import { Provider } from 'react-redux';
import React, { Suspense, lazy } from 'react';
import { persistor, store } from './redux/store';
import { RequireAuth } from './routes/RequireAuth';
import { RequireLogin } from './routes/RequireLogin';
import SimpleRootLayout from './routes/SimpleRootLayout';
import SpinnerLoader from './components/utils/SpinnerLoader';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { loggedRouters, staticRouters } from './routes/RouterElements';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// react lazy pages

const HomeRouter = lazy(() => import('./routes/HomeRouter'));
const UserRouter = lazy(() => import('./routes/UserRouter'));
const RootLayout = lazy(() => import('./routes/RootLayout'));
const UserLogin = lazy(() => import('./components/auth/UserLogin'));
const LangRootLayout = lazy(() => import('./routes/LangRootLayout'));
const UserRegister = lazy(() => import('./components/auth/UserRegister'));
const UserValidate = lazy(() => import('./components/dashboard/users/UserValidate'));

function App() {

  // react query options

  const queryClient = new QueryClient();

  // routers options

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<RootLayout />}>
        <Route index element={<HomeRouter />} />
        <Route path='register' element={<UserRegister />} />
        <Route path='login' element={
          <RequireLogin>
            <UserLogin />
          </RequireLogin>
        } />
        <Route path='users/validate' element={<SimpleRootLayout />}>
          <Route path=':id' element={
            <RequireLogin>
              <UserValidate />
            </RequireLogin>
          } />
        </Route>

        {staticRouters?.map((item, int) => (
          <Route key={int} path={item?.path} element={item?.element} />
        ))}

        <Route element={
          <RequireAuth>
            <UserRouter />
          </RequireAuth>
        }>
          {loggedRouters?.map((item, int) => (
            <Route key={int} path={item?.path}>
              <Route index element={item?.index} />
              {item?.childrens?.map((child, count) => (
                <Route key={count} path={child?.childPath} element={child?.element} />
              ))}
            </Route>
          ))}
        </Route>

        <Route path=':lang' element={<LangRootLayout />}>
          <Route index element={<HomeRouter />} />
          <Route path='register' element={<UserRegister />} />
          <Route path='login' element={
            <RequireLogin>
              <UserLogin />
            </RequireLogin>
          } />
          <Route path='users/validate' element={<SimpleRootLayout />}>
            <Route path=':id' element={
              <RequireLogin>
                <UserValidate />
              </RequireLogin>
            } />
          </Route>

          {staticRouters?.map((item, int) => (
            <Route key={int} path={item?.path} element={item?.element} />
          ))}

          <Route element={
            <RequireAuth>
              <UserRouter />
            </RequireAuth>
          }>
            {loggedRouters?.map((item, int) => (
              <Route key={int} path={item?.path}>
                <Route index element={item?.index} />
                {item?.childrens?.map((child, count) => (
                  <Route key={count} path={child?.childPath} element={child?.element} />
                ))}
              </Route>
            ))}
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <Suspense fallback={<SpinnerLoader />}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  )
}

export default App
